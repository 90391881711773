import React from 'react'

function Refund() {
  return (
    <div className='flex mt-40 mb-6 flex-col container gap-4 text-justify'>
    <div className='p-4' ><h1 className='text-3xl text-red-600 font-semibold'>Cancellation Policy</h1></div>
    <div className='px-10 flex flex-col gap-2'>
    <h3 className='text-lg font-medium'>NO REFUND SHALL BE MADE WITH RESPECT TO THE INITIAL BOOKING AMOUNT FOR ANY CANCELLATIONS. HOWEVER,</h3>
        <p>1. If cancellations are made 30 days before the start date of the trip, 50% of the trip cost will be charged as cancellation fees.</p>
        <p>2. If cancellations are made 15-30 days before the start date of the trip, 75% of the trip cost will be charged as cancellation fees.</p>
        <p>3.If cancellations are made within 0-15 days before the start date of the trip, 100% of the trip cost will be charged as cancellation fees.</p>
        
        <p>4. In the case of unforeseen weather conditions or government restrictions, certain activities may be canceled and in such cases, the operator will try his best to provide an alternate feasible activity. However, no refund will be provided for the same.</p>
    </div>
    
 
</div>
  )
}

export default Refund
