import React,{useContext,useState ,useRef} from 'react'
import { useForm } from 'react-hook-form';
import '../components/Footer.css'

import axios from 'axios';
import { Link } from 'react-router-dom'

import { MdLocalPhone, MdLocationPin, MdLocalPostOffice, MdCopyright  } from "react-icons/md";
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { baseurl } from '../Url';





const  Footer =() => {
    const subscribe = useRef();
    const[errorMessage,setErrorMessage]=useState('');
    const { register, handleSubmit } = useForm();
    const[open,setOpen]=useState(false);
    const [formData, setFormData] = useState({
       
        email: ''
      });
      const handleInput = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
 
   
    let Links=[
        {name:'Home' ,link:'/'},
        {name:'Activities' ,link:'/activities'},
        {name:'Hotels' ,link:'/hotels'},
       
        {name:'Contact Us' ,link:'/contactus'}
    ]
    const onSubmit = (formData) => {
        setErrorMessage('Please wait...')
        console.log('Form Data JSON:', formData);
        // You can now use formDataJson for your API call or other processing
        axios.post(`${baseurl}/subscriptions`, formData).then(response=>{
            setErrorMessage('');
          alert("Thank You for reaching out, We will Contact You soon...")
        })
        .catch(error=>{
          console.error('There was an error!', error);
          alert("Please enter a different mail!!!")
          setErrorMessage('Try using different mail!');
        })
        
      };
  return (
    <footer className='footer  bg-black/20  w-full ' >
        <div>
        <div style={{backgroundImage:'url(/assets/bg-img-subscribe-button.png)'}} className="footer-top">
            <form  onSubmit={handleSubmit(onSubmit)} className="text-cemter flex flex-col justify-center items-center " id='newsletter'>
                <input {...register('email')} onChange={handleInput} value={formData.email} className="form-control"  placeholder="Enter Your email address"/>
                <button  className="form-submit  hover:bg-white hover:text-black" type="submit">Subscribe Now</button>
                {errorMessage && <p className='text-xl font-medium shadow-md' style={{ color: 'white' }}>{errorMessage}</p>}
            </form>

        </div>
        </div>
        <div className='footer-bottom items-center justify-center  lg:px-0  '>
        <div className='footer-bottom-col '>
            <div className="block">
                    <h3 className="footer-title text-2xl">About Us</h3>
                    <p className='box text-sm text-balance '>
                        Explore the world with the most experienced travel planners. Either it's a business trip or a
                        travel
                        tour, we are ready to serve all your needs according to your preferences.
    
                    </p>
                </div>
            </div>
        <div className='footer-bottom-col '>
            <h3 className="footer-title text-2xl ">Quick links</h3>
                <ul>
                {
            Links.map(link=>
                        <li className='hover:translate-x-2 hover:italic hover:font-semibold  my-0 ml-2 text-left text-xl justify-around '>
                            <Link  to={link.link}>{link.name}</Link>
                        
                        </li>
            )
        }
                </ul>
            </div>
            
           
          
            <div className='footer-bottom-col '>
            <h3 className="footer-title text-2xl">Address</h3>
            <div className='flex'>
            <div><MdLocationPin size={20}/></div>
                <p className="dsvbe flex flex-col font-medium">India Office:
             <span class="trav dsvbe text-sm  font-light">   D-1A, Sir Pratap
                    Colony
                    ,Jodhpur, Rajasthan, 342011 India </span> </p>
               
                </div>
            <div className='flex'>
                <div><MdLocationPin size={20}/></div>
                
                <p className="dsvbe flex flex-col font-medium"> USA Office: 
                    <span class="trav dsvbe text-sm font-light">Dubuque, Iowa, The USA</span>
                </p>
               
                </div>
             
           
            </div>
            {/* <div className='footer-bottom-col '>
            <h3 className="footer-title text-2xl">Map</h3>
            
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14309.160965178298!2d73.00289610781249!3d26.284689400000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39418c4afdf98417%3A0x16a55036706a7762!2sSIR%20PRATAP%20COLLEGE!5e0!3m2!1sen!2sin!4v1673600588415!5m2!1sen!2sin"
                    width="100%" height="150" style={{border:0}} allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>

           
            </div> */}
            <div className='footer-bottom-col  justify-evenly'>
                <h3 className="footer-title  text-2xl">Contact Us</h3>
               
                
                <div className='flex hover:translate-x-2  '>
                    <div><MdLocalPostOffice size={20}/></div>
                
                <p className="dsvbe"> <a class="peerto" target="_blank"
                        href="mailto:info@indiaouttabox.com">info@indiaouttabox.com</a> </p>
               
                </div>
                <div className='flex hover:translate-x-2 mt-4'>
                    <div><MdLocalPhone size={20}/> </div>
                
                <p className="dsvbe"><a class="peerto" target="_blank"
                        href="tel:+919166222900"> (+91)9166222900 </a> </p>

                </div>
                <div className='flex gap-4  mt-4 '>
                    <Link target="_blank" to='https://www.instagram.com/indiaouttabox' className='cursor-pointer hover:rotate-6 hover:translate-x-1'>  <FaInstagram  size={30}/></Link>
               
                <Link target="_blank" to='https://facebook.com/indiaouttabox?wtsid=rdr_0iBybpjpGmGvkUHjp'  className='cursor-pointer hover:rotate-6 hover:translate-x-1'><FaFacebook  size={30}/></Link>
                <Link target="_blank" to='https://in.linkedin.com/company/indiaouttabox' className='cursor-pointer hover:rotate-6 hover:translate-x-1'><FaLinkedin  size={30}/></Link>
                
                
                </div>
                 
            </div>

        </div>
   
       <div className='flex font-medium justify-center gap-5 bg-black/70 text-white'>
       <span className=" cursor-pointer" ><Link to={'/terms'}>Terms of Service</Link></span>
       <span className='cursor-pointer'><Link to={'/privacy'}>Privacy Policy</Link></span>
       <span className='cursor-pointer'><Link to={'/refund-policy'}>Refund Policy</Link></span>
       </div>
        <div className='flex flex-wrap items-center border p-2 gap-4 border-t-red-700 px-10 justify-center'> 
        <div className=' flex font-mono text-center justify-center items-start text-2xl italic'>
       <div ><MdCopyright size={15}/></div> 
        <span  className="copyright-txt text-sm">Copyright 2024 India Outtabox Pvt Ltd. All Right Reserved.</span>
      
       
        </div>
            </div>
      
     
    </footer>
  )
}

export default Footer
