import React from 'react'

function Terms() {
  return (
    <div className='flex mt-40 mb-6 flex-col container gap-4 text-justify'>
        <div className='p-4' ><h1 className='text-3xl text-red-600 font-semibold'>Terms and Conditions</h1></div>
        <div className='px-10'>
            <h3 className='text-xl font-medium'>Insurance</h3>
            <p>User Responsibility: Obtaining sufficient insurance coverage is the obligation of the User1. India Outtabox Pvt. Ltd. IOPL shall not accept any claims arising out of insufficient insurance coverage.</p>
            <p>Third-Party Insurance: Insurance provided as part of the service or product by IOPL shall be as per the terms and conditions of the third-party insurance company2. IOPL merely acts as a facilitator in connecting the User with the insurance company3.
            </p>
        </div>
        <div className='px-10'>
            <h3 className='text-xl font-medium'>Overseas Tour Packages</h3>
            <p>Definition: An ‘Overseas tour package’ includes any tour package offering visits to countries outside India, covering expenses for travel, hotel stay, boarding, lodging, or any related expenditure4.</p>
            <p>
            Tax Collected at Source (TCS): Users are liable to pay TCS on booking an overseas tour package as per Section 206C (1G) (b) of the Income Tax Act, 19615. IOPL will deposit the TCS amount with the Government and issue the appropriate document/certificate to the User.</p>
        </div>
        <div className='px-10'>
            <h3 className='text-xl font-medium'>PAN Details</h3>
            <p>Requirement: Users must provide a valid PAN at the time of booking an overseas tour package6. IOPL will validate the PAN to ensure compliance with Section 206CC and Section 206CCA of the Income Tax Act.</p> 
            <p>
            Non-Compliance: If the PAN is invalid or does not belong to the User/traveler, IOPL may cancel the booking and process the refund as per the applicable cancellation policy7.</p>
        </div>
        <div className='px-10'>
            <h3 className='text-xl font-medium'>Visa Requirements</h3>
            <p>
            User Responsibility: Users must obtain the necessary visas, including transit visas, as required for their travel bookings8. IOPL is not responsible for any issues arising from visa requirements and is not liable to refund any amount due to visa-related issues.</p> 
            
        </div>
        <div className='px-10'>
            <h3 className='text-xl font-medium'>Force Majeure</h3>
            <p>
            Circumstances: IOPL and/or Service Providers may be unable to honor confirmed bookings due to reasons beyond their control, such as natural disasters, labor unrest, insolvency, pandemics, government decisions, terrorist activity, operational issues, etc9.</p> 
            <p>
            Refunds: In such cases, IOPL will make efforts to provide similar alternatives or refund the booking amount after deducting applicable service charges, if supported and refunded by the respective service operators.
            </p> 
            
        </div>
        <div className='px-10'>
            <h3 className='text-xl font-medium'>User Conduct </h3>
            <p>
            Correct Information: Users must provide correct and valid information while using the IOPL website. Misrepresentation of facts may result in cancellation of bookings without prior intimation.
            </p> 
            <p>
            Correct Information: Users must provide correct and valid information while using the IOPL website. Misrepresentation of facts may result in cancellation of bookings without prior intimation.
            </p> 
            <p>Fraud Prevention: IOPL reserves the right to deny or cancel any bookings suspected of fraud10.
            Confidentiality</p>
            <p>User Information: Any information specifically mentioned by IOPL as confidential shall be maintained confidentially by the User and shall not be disclosed unless required by law11.</p>
            
        </div>
        <div className='px-10'>
            <h3 className='text-xl font-medium'>
            Feedback and Privacy </h3>
            <p>
            Feedback: IOPL may contact Users for feedback on services offered. Users can opt-out by contacting IOPL.
            </p> 
            <p>
            
            Privacy Policy: Users agree to the terms of IOPL’s Privacy Policy by using the website.
            </p> 
            
        </div>
        <div className='px-10'>
            <h3 className='text-xl font-medium'>Grievance Redressal </h3>
            <p>
            Resolution: IOPL aims to resolve User issues within 30 days. Users can escalate unresolved issues to the Grievance Officer through the provided link.
            </p> 
            <p>
            
            </p> 
            
        </div>
        <div className='px-10 pb-20'>
            <h3 className='text-xl font-medium'> 
            Jurisdiction</h3>
            <p>
            Legal Interpretation: This Agreement is subject to interpretation as per the laws of India, and disputes shall be referred to the exclusive jurisdiction of courts in Delhi12.
            </p> 
            <p>
            
            </p> 
            
        </div>
      {/* 
Insurance
















 */}
    </div>
  )
}

export default Terms
